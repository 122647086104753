export const device = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '834px',
  laptop: '1024px',
  laptopL: '1440px',
  laptopXL: '1680px',
  desktop: '2560px',
};
