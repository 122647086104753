import styled from 'styled-components';

const SwitchButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 12px;
  width: 104px;
  height: 50px;
  background: transparent;
  border-style: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.textMenu};
`;

const SwitchTitle = styled.span`
  line-height: 27px;
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
`;

const DropdownMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 104px;
  height: 101px;
  margin: 0px;
  background: #e5e5ea;
  line-height: 1.25rem;
`;

const DropdownList = styled.li`
  display: block;
  height: 50px;
  margin: 0;
  background: white;
  :hover {
    background-color: #f3f4f6;
  }
`;

const DropdownLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  padding: 12px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => (props.active ? '#D62E2E' : '#202124')};
`;

const IconWrapper = styled.img`
  margin-right: 16px;
`;

const SmallSizeWrapper = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.color.textMenu};
  @media (max-width: 1325px) {
    ${({ theme }) => theme.typography.link()};
    padding: 20px 0px 20px 56px;
  }
  @media (max-width: 768px) {
    padding: 20px 0px 20px 24px;
  }
`;

const SmallSizeTitle = styled.span`
  line-height: 14px;
  font-weight: 400;
  font-size: 12px;
`;

const SmallSizeMenu = styled.div`
  padding: 16px 0px;
`;

const SmallSizeLanguageList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  margin-left: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500px;
  color: ${(props) => props.active && '#D62E2E'};
`;

export {
  SwitchButton,
  SwitchTitle,
  Dropdown,
  DropdownMenu,
  DropdownList,
  DropdownLink,
  IconWrapper,
  SmallSizeWrapper,
  SmallSizeTitle,
  SmallSizeMenu,
  SmallSizeLanguageList,
};
