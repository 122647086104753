export const socialIconAlt = {
  LINKEDIN: 'LinkedIn',
};

export const ventures = {
  fanz: {
    url: 'https://www.fanz.io/',
    altText: 'Fanz',
  },
  arbotena: {
    url: 'https://arbotena.de/',
    altText: 'Arbotena',
  },
  nuvo: {
    url: 'http://getnuvo.com/',
    altText: 'Nuvo',
  },

  foodplace: {
    url: 'https://foodplace.eu/',
    altText: 'Foodplace',
  },
  proofler: {
    url: 'https://www.proofler.de/',
    altText: 'Proofler',
  },
};

export const contentIconAlt = {
  PLAY: 'Play',
};

export const quoteCardIconAlt = {
  QUOTATION_MARK: 'Quotation mark',
};

export const footerIconAlt = {
  LOGO20SCOOPS: '20Scoops',
};

export const contactPage = {
  altText: {
    XING: 'Xing',
  },
  ariaLabel: {
    hamburg: {
      FACEBOOK: 'Link to 20Scoops Facebook page',
      TWITTER: 'Link to 20Scoops Twitter',
      INSTAGRAM: 'Link to 20Scoops Instagram',
      XING: 'Link to 20Scoops XING',
    },
    chiangMai: {
      FACEBOOK: 'Link to 20Scoops CNX Facebook page',
      LINKEDIN: 'Link to 20Scoops CNX LinkedIn',
      MEDIUM: 'Link to 20Scoops CNX Medium',
      INSTAGRAM: 'Link to 20Scoops CNX Instagram',
      GITHUB: 'Link to 20Scoops CNX Github',
    },
  },
};

export const contactSection = {
  ariaLabel: {
    FACEBOOK: 'Link to 20Scoops CNX Facebook page',
    LINKEDIN: 'Link to 20Scoops LinkedIn',
  },
};

export const navBar = {
  ariaLabel: {
    LOGO20SCOOPS: 'Link to home page',
  },
};

export const blogPage = {
  altText: {
    paginationIcon: {
      PREVIOUS: 'Previous blog',
      NEXT: 'Next blog',
    },
  },
};
