import { useState } from 'react';
import { useHandleLanguage } from '../../contexts/language.context';
import { useOutsideClick } from '../../hook/useOutsideClick';

export const useViewModel = () => {
  const [isShowDropdownMenu, setIsShowDropdownMenu] = useState(false);

  const { lang, t } = useHandleLanguage();
  const langTitle = t('lang_title');

  const handleClickOutside = () => {
    setIsShowDropdownMenu(false);
  };

  const handleClickButton = () => {
    setIsShowDropdownMenu((prev) => !prev);
  };

  const handleSelectLanguage = (langSelected) => {
    setIsShowDropdownMenu(false);
    if (langSelected === lang) {
      return;
    }

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('language', langSelected);
      window.location.reload();
    }
  };

  const ref = useOutsideClick(handleClickOutside);
  return {
    lang,
    langTitle,
    handleClickButton,
    handleSelectLanguage,
    isShowDropdownMenu,
    ref,
  };
};
