import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useHandleLanguage } from '../../contexts/language.context';

const InternalLinkMenu = styled(Link)`
  ${({ theme }) => theme.typography.link()};
  display: inline-block;
  white-space: nowrap;
  transition: all 200ms ease-in;
  position: relative;
  width: 100%;
  opacity: 0.6;
  padding: 0 12px;

  color: ${({ theme }) => theme.color.textMenu};

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${({ theme }) => theme.color.textSubMenu};
    ::after {
      width: 100%;
    }
  }

  &.activeStyles {
    color: ${({ theme }) => theme.color.textBlack};
    opacity: 1;
  }

  @media (max-width: 1325px) {
    ${({ theme }) => theme.typography.link()};
    padding: 1px 0px 1px 56px;

    z-index: 6;
    filter: none;
    margin: 24px 0px;
    text-align-last: left;
    span {
      margin: 0 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 1px 0px 1px 24px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 24px 0px;
    :hover {
      color: ${({ theme }) => theme.color.textMenu};
    }
  }
`;
const ExternalLinkMenu = styled.a`
  ${({ theme }) => theme.typography.link()};
  display: inline-block;
  white-space: nowrap;
  transition: all 200ms ease-in;
  position: relative;
  width: 100%;
  opacity: 0.6;
  padding: 0 12px;

  color: ${({ theme }) => theme.color.textMenu};

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${({ theme }) => theme.color.textSubMenu};
    ::after {
      width: 100%;
    }
  }

  &.activeStyles {
    color: ${({ theme }) => theme.color.textBlack};
    opacity: 1;
  }

  @media (max-width: 1325px) {
    ${({ theme }) => theme.typography.link()};
    padding: 1px 0px 1px 56px;

    z-index: 6;
    filter: none;
    margin: 24px 0px;
    text-align-last: left;
    span {
      margin: 0 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 1px 0px 1px 24px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 24px 0px;
    :hover {
      color: ${({ theme }) => theme.color.textMenu};
    }
  }
`;

const Menu = ({ opacity, appearable }) => {
  const { t } = useHandleLanguage();

  return (
    <>
      <InternalLinkMenu
        to="/whatwedo"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
      >
        <span>What we do</span>
      </InternalLinkMenu>
      <ExternalLinkMenu
        href="https://20scoopscnx.com/culture"
        target="_blank"
        rel="noopener noreferrer"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
      >
        <span>Team CNX</span>
      </ExternalLinkMenu>
      <ExternalLinkMenu
        href="https://20scoopscnx.com/tech-stack"
        target="_blank"
        rel="noopener noreferrer"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
      >
        <span>Tech Stack</span>
      </ExternalLinkMenu>
      <InternalLinkMenu
        to="/blog"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
      >
        <span>Blog</span>
      </InternalLinkMenu>
      <InternalLinkMenu
        to="/contact"
        opacity={opacity}
        appearable={appearable.toString()}
        activeClassName="activeStyles"
        partiallyActive={true}
      >
        <span>{t('contact_menu')}</span>
      </InternalLinkMenu>
    </>
  );
};

export default Menu;
