import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import Menu from './menu';
import LanguageSwitchButton from '../languageSwitchButton';

// data
import { Link } from 'gatsby';

import { useMedia } from '../../hook/index';
import Logo20Scoops from '../../images/Logo20ScoopsVC.svg';
import { navBar } from '../../constants';

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 90px;
  margin: 0 auto;
  padding: 0 114px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 100;
  align-self: center;

  @media (max-width: 1112px) {
    padding: 0 56px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 24px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  background-color: rgba(253, 253, 253, ${(props) => props.opacity});
  color: white;

  backdrop-filter: ${({ opacity }) => (opacity === 0 ? 'none' : 'blur(5px)')};
  transition: all 0.3s;
`;

const Toggle = styled.div`
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding-top: 20px;
  z-index: 10;
`;

const Hamburger = styled.div`
  background-color: ${({ theme, opacity, appearable }) =>
    opacity === 0 && !appearable ? theme.color.uiColorGray : theme.color.uiColorGray};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${({ theme, opacity, appearable }) =>
      opacity === 0 && !appearable ? theme.color.uiColorGray : theme.color.uiColorGray};
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const NavBarbox = styled.div`
  ${({ theme }) => theme.typography.formHeadline()};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  text-align: left;

  @media (max-width: 1325px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    text-align: center;
    transition: all 0.3s ease-in;
    padding-top: 107px;
    line-height: 27px;
    top: 0;
    left: ${(props) => (props.open ? '-150%' : '0')};
    background-color: ${({ theme }) => theme.color.textWhite};
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    height: 100vh;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

const RightMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTabletWrapper = styled.div`
  padding-bottom: 5px;
  padding-right: 23px;
`;

const Header = ({ activeDocMeta, location }) => {
  const [opacity, setOpacity] = useState(0.9);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const expandAble =
    activeDocMeta.type === 'privacy-markdown' ||
    activeDocMeta.type === 'imprint-page' ||
    activeDocMeta.type === 'blog-page' ||
    activeDocMeta.type === 'blog-post';

  const isSmallSize = useMedia(`(max-width: 1325px)`);
  const isMobileSize = useMedia(`(max-width: 500px)`);

  useEffect(() => {
    if (window.pageYOffset) {
      setOpacity(0.9);
    }

    const headerOnScroll = () => {
      let op = window.pageYOffset;
      if (window.scrollY) {
        op = 100;
        setOpacity(op * 0.009);
      } else {
        setOpacity(0);
      }
    };

    window.addEventListener('scroll', headerOnScroll);

    return () => {
      window.removeEventListener('scroll', headerOnScroll);
    };
  }, [opacity]);

  const { pathname } = location;

  // TODO: check path name to including tags.
  const tagsPath = !!pathname ? pathname.split('/')[2] : '';

  // TODO: active menu blogs
  const blogPath =
    !!tagsPath && pathname.split('/')[1].includes('blog') ? pathname.split('/')[1] : null;

  // TODO: active menu case
  const casePath =
    !!tagsPath && pathname.split('/')[1].includes('case') ? pathname.split('/')[1] : null;

  useEffect(() => {
    window.addEventListener('resize', setNavbarOpen(false));

    return () => {
      window.removeEventListener('resize', setNavbarOpen(false));
    };
  }, []);

  return (
    <Navigation opacity={opacity}>
      {isSmallSize ? (
        <>
          <LogoWrap>
            <Link
              to="/"
              css={`
                z-index: 20;
              `}
              aria-label={navBar.ariaLabel.LOGO20SCOOPS}
            >
              <img
                alt="logo"
                id="logo"
                src={Logo20Scoops}
                css={`
                  height: 64px !important;
                  width: 188px !important;
                  z-index: 10 !important;
                  max-width: unset !important;
                `}
                loading="eager"
              />
            </Link>
          </LogoWrap>
          <RightMenuWrapper>
            {!isMobileSize && (
              <ButtonTabletWrapper>
                <LanguageSwitchButton isMenu={isMobileSize} />
              </ButtonTabletWrapper>
            )}
            <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? (
                <Hamburger open opacity={opacity} appearable={expandAble} />
              ) : (
                <Hamburger opacity={opacity} appearable={expandAble} />
              )}
            </Toggle>
          </RightMenuWrapper>
          <NavBarbox opacity={opacity} open={!navbarOpen}>
            <div
              id="NavBarbox-wrapper"
              css={`
                background-color: #ffffff;
                width: 100%;
                height: 100%;
                overflow: auto;
                padding-bottom: 32px;
              `}
            >
              <Menu
                opacity={opacity}
                appearable={expandAble}
                isBlogs={!!blogPath}
                isSmallSize={isSmallSize}
              />
              {isSmallSize && <LanguageSwitchButton isMenu={isSmallSize} />}
            </div>
            <div
              css={`
                height: 100px;
                width: 100%;
                background-color: transparent;
                opacity: 0.1;
              `}
            ></div>
          </NavBarbox>
        </>
      ) : (
        <>
          <LogoWrap>
            <Link
              to="/"
              css={`
                z-index: 20;
                display: flex;
                align-items: center;
              `}
            >
              <img
                alt="logo"
                id="logo"
                src={Logo20Scoops}
                css={`
                  && {
                    height: 64px;
                    width: 188px;
                    z-index: 10;
                    max-width: unset;
                  }
                `}
              />
            </Link>
            <NavBarbox
              opacity={opacity}
              open={!navbarOpen}
              css={`
                margin-left: 34px;
              `}
            >
              <Menu
                opacity={opacity}
                appearable={expandAble}
                isBlogs={!!blogPath}
                isCase={!!casePath}
                isSmallSize={isSmallSize}
              />
            </NavBarbox>
          </LogoWrap>
          <LanguageSwitchButton />
        </>
      )}
    </Navigation>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
