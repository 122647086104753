import React from 'react';

import { useViewModel } from './viewModel';

import LanguageGlobalIcon from '../../images/LanguageGlobalIcon.svg';
import LanguageGermanIcon from '../../images/LanguageGermanIcon.svg';
import LanguageEnglishIcon from '../../images/LanguageEnglishIcon.svg';
import DropdownIcon from '../../images/DropdownIcon.svg';

import {
  SwitchButton,
  SwitchTitle,
  Dropdown,
  DropdownMenu,
  DropdownList,
  DropdownLink,
  IconWrapper,
  SmallSizeWrapper,
  SmallSizeTitle,
  SmallSizeMenu,
  SmallSizeLanguageList,
} from './index.view';

function LanguageSwitchButton({ isMenu = false }) {
  const {
    lang,
    langTitle,
    handleClickButton,
    handleSelectLanguage,
    isShowDropdownMenu,
    ref,
  } = useViewModel();

  return (
    <>
      {!isMenu ? (
        <div ref={ref}>
          <SwitchButton onClick={handleClickButton}>
            <img alt="LanguageGlobalIcon" src={LanguageGlobalIcon} />
            <SwitchTitle>{langTitle}</SwitchTitle>
            <img alt="DropdownIcon" src={DropdownIcon} />
          </SwitchButton>
          <Dropdown>
            {isShowDropdownMenu && (
              <DropdownMenu>
                <DropdownList>
                  <DropdownLink
                    onClick={() => handleSelectLanguage('de-de')}
                    active={lang === 'de-de'}
                  >
                    <img alt="LanguageGermanIcon" src={LanguageGermanIcon} />
                    {'DE'}
                  </DropdownLink>
                </DropdownList>
                <DropdownList>
                  <DropdownLink
                    onClick={() => handleSelectLanguage('en-us')}
                    active={lang === 'en-us'}
                  >
                    <img alt="LanguageEnglishIcon" src={LanguageEnglishIcon} />
                    {'EN'}
                  </DropdownLink>
                </DropdownList>
              </DropdownMenu>
            )}
          </Dropdown>
        </div>
      ) : (
        <>
          <SmallSizeWrapper>
            <SmallSizeTitle>LANGUAGES</SmallSizeTitle>
            <SmallSizeMenu>
              <SmallSizeLanguageList
                onClick={() => handleSelectLanguage('de-de')}
                active={lang === 'de-de'}
              >
                <IconWrapper alt="LanguageGermanIcon" src={LanguageGermanIcon} />
                <span>{'Deutsch'}</span>
              </SmallSizeLanguageList>
              <SmallSizeLanguageList
                onClick={() => handleSelectLanguage('en-us')}
                active={lang === 'en-us'}
              >
                <IconWrapper alt="LanguageEnglishIcon" src={LanguageEnglishIcon} />
                <span>{'English'}</span>
              </SmallSizeLanguageList>
            </SmallSizeMenu>
          </SmallSizeWrapper>
        </>
      )}
    </>
  );
}

export default LanguageSwitchButton;
