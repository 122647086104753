/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useHandleLanguage } from '../contexts/language.context';
function SEO({ description, meta, title, ogImage = '' }) {
  const lang = useHandleLanguage();
  const pageLanguage = lang.lang;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const ogImageLanding = `https://20scoopsvc-video.s3.eu-central-1.amazonaws.com/Thumbnail.png`;

  return (
    <Helmet
      htmlAttributes={{ lang: pageLanguage }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageLanding,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImageLanding,
        },
      ].concat(meta)}
    >
      <script data-cookieconsent="ignore" type="text/javascript" />
      <script
        data-cookieconsent="ignore"
        id="_etLoader"
        type="text/javascript"
        charset="UTF-8"
        data-block-cookies="true"
        data-respect-dnt="true"
        data-secure-code="6ggHD3"
        src="https://code.etracker.com/code/e.js"
        async
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  title: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
};

export default SEO;
