import React, { createContext, useEffect, useState } from 'react';
import Stats from '../utils/framerate';

export const AnimationFunc = createContext({});

export const AnimationProvider = ({ children }) => {
  const lackingCount = [];
  useEffect(() => {
    document.body.onload = () => {
      var stats = new Stats();
      requestAnimationFrame(function loop() {
        stats.update();
        if (stats.getMs() > 50) lackingCount.push(true);
        if (lackingCount.length > 500) return setIsAnimationWork(false);
        requestAnimationFrame(loop);
      });
    };
  }, [lackingCount]);

  const [isAnimationWork, setIsAnimationWork] = useState(true);

  return (
    <AnimationFunc.Provider value={{ isAnimationWork, setIsAnimationWork }}>
      {children}
    </AnimationFunc.Provider>
  );
};
