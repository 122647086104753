var Stats = function () {
  var ms = 0;

  var beginTime = (performance || Date).now();

  return {
    REVISION: 16,
    begin: function () {
      beginTime = (performance || Date).now();
    },
    end: function () {
      var time = (performance || Date).now();
      ms = time - beginTime;

      return time;
    },
    update: function () {
      beginTime = this.end();
    },
    getMs: () => {
      return ms;
    },
  };
};

export { Stats as default };
